import { getTokenAllowance, setTokenAllowance } from './erc20';

export async function handleAllowance(
  tokenContractAddress: string,
  owner: string,
  spender: string,
  amount: bigint
) {
  const tokenAllowance = await getTokenAllowance(tokenContractAddress, owner, spender);
  const tokenHasEnoughAllowance = tokenAllowance > amount;

  if (!tokenHasEnoughAllowance) {
    const approveSuccess = await setTokenAllowance(tokenContractAddress, spender);
    return approveSuccess;
  }
  return true;
}
